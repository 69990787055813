export const GlobalRoutePath = {
    LOGIN: {
      path: "/login"
    },
    SIGNUP: {
      path: "/signup",
    },
    SIGNUP_CONFIRM: {
      path: "/signupconfirmation",
    },
    FORGOT_PASSWORD: {
      path: "/forgotpassword",
    },
    FORGOT_PASSWORD_CONFIRM: {
      path: "/forgotpasswordconfirm",
    },
    CHANGE_PASSWORD: {
      path: "/changepassword",
    },
    TERMS_AND_CONDITIONS: {
      path: "/termsandconditions",
    },
    LEAD_GENERATION: {
      path: "/leadgeneration",
    },
    DEFAULT: {
      path: "/",
    },
    HOME: {
      path: "/home",
    },
    LANDING: {
      path: "/mydashboard",
      privilege: "DASHBOARDS",
    },
    DASHBOARD: {
      path: "/mydashboard/:dashboardId",
      privilege: "DASHBOARDS",
    },
    USER_PROFILE: {
      path: "/userprofile",
    },
    ADT_AUTOMATION:{
      path: "/adtautomation",
    },
    ADT_AUTOMATION_HISTORY:{
      path: "/adtautomationhistory",
    },
    SETTINGS: {
      path: "/settings",
    },
    AI_SERVICES: {
      path: "/ai-services",
      privilege: "AI_SERVICES",
    },
    PATIENT360: {
      path: "/patient360",
      privilege: "PATIENT-360"
    },
    PATIENTTRIAL: {
      path: "/patienttrial",
      privilege: "PATIENT_TRIAL",
    },
    TRIAL_VIEW: {
      path: "/patienttrial/:viewId",
      privilege: "PATIENT_TRIAL"
    },
    VIEW: {
      path: "/patient360/:view_id",
    },
    SMART_SCHEDULER: {
      path: "/smart-scheduler",
      privilege:"SMART_SCHEDULER"
    },
    SMART_FORMS: {
      path: "/smart-forms",
      privilege: "SMART_FORM",
    },
    EFAX: {
      path: "/efax",
      privilege:"TOOLS_EFAX"
    },
    DEVELOPER_TOOLS_CREATE_VIEW: {
      path: 'developer-world/create-view',
    },
    PRACTICES: {
      path: '/practice-management',
      privilege: 'PRACTICE_MANAGEMENT'
    },
    JOB_SCHEDULER_EXECUTED: {
      path: '/practice-jobs-executed',
      privilege: 'PRACTICE_MANAGEMENT'
    },
    JOB_DASHBOARD: {
      path: '/job-dashboard',
      privilege: 'PRACTICE_MANAGEMENT'
    }
};

export const postLoginPath = ()=>localStorage.getItem(_app.u.luid+"lpath") || GlobalRoutePath.LANDING.path