import React, { useState,useContext, useEffect,useRef } from 'react';
import { Link,matchPath,useNavigate } from "react-router-dom";
import { useSelector,useDispatch,shallowEqual } from "react-redux";

import { loginActions } from "@redux/actionCreators/UserActions";
import {PermissionContext} from "../PermissionContext";
import { makeStyles } from "@mui/styles";
import {Accordion,AccordionDetails,AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SupportIcon from "@mui/icons-material/ContactSupport";
import InputAdornment from '@mui/material/InputAdornment';
import TextFieldWrapper from "@components/common/TextFieldWrapper";
import { useStyles } from "./styles";
import { adtAutoNavItems, settingsNavItems } from "./content";
import { GlobalRoutePath } from "@router/constants";
import { SettingsRoutes } from '@settings/Routes';
import SvgIcon from "../common/SvgIcon";
import ClearIcon from '@mui/icons-material/Clear';
import Logo from '@common/Logo';
import {SHOW_GLOBAL_ALERT} from "@utils/Events/constants";

const stateSelector = state => ({
  selectedClinicId: state.clinicReducer.selectedClinicId,
  clinicDashboards: state.clinicReducer.clinicDashboards,
  viewDetails: state.patient360Reducer.viewDetails,
  trialViewList:state.patientTrialReducer.trialViewList || []
});
const styles = makeStyles(useStyles);
const dynaClass = window.innerHeight <= 800 ? 'sm-h':'lg-h';
const LeftMenu = ({handleClick,open,onRefresh }) => {
  const classes = styles();
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const { selectedClinicId, clinicDashboards, viewDetails,trialViewList} = useSelector(state => stateSelector(state),shallowEqual);
  const permissions = useContext(PermissionContext);
  const helpPath = SettingsRoutes.HELP.fullPath;
  const isDashboardPage = pathName.includes(GlobalRoutePath.LANDING.path)
  const helpcenter = (<div className={classes.helpCenterTextContainer}>
                      <SupportIcon />
                      <Link to={SettingsRoutes.HELP.fullPath}>Help Center</Link>
                    </div>);
  const settingPath = !pathName.includes(GlobalRoutePath.SETTINGS.path) && (permissions.includes("SERVICE SUBSCRIPTION")?GlobalRoutePath.SETTINGS.path:helpPath);                  
  const [searchText,setSerchText] = useState('');  
  const [selectedPath,setSelected] = useState(pathName);
  const dispatch = useDispatch();
  const confirmLogout = (e)=>{
    e.preventDefault();
    _app.events.trigger(SHOW_GLOBAL_ALERT,{title:"Are you sure you want to logout?",cancelText:'Cancel', okText:"Yes, log me out",onOkClick:handleSignout});
  }
  useEffect(()=>{
    window.location.pathname!=selectedPath && setSelected(window.location.pathname)
  },[window.location.pathname])
  const handleSignout = (e) => {
    e.preventDefault();
    dispatch(loginActions.signout);
    navigate(GlobalRoutePath.HOME.path);
  };
  const onClick = (p,refresh)=>{
    //console.log('e.target.href',e.target.href);
    setSelected(p);
    refresh && onRefresh();
  }
  const activeDashboards = ({dashboards})=>{
    const regex = new RegExp(searchText, "im")
    return dashboards.filter(i=> i.is_active && regex.test(i.display_name));
  }                
  const userDashborads = ()=>{
    return(isDashboardPage && clinicDashboards[selectedClinicId]?
      clinicDashboards[selectedClinicId].category.
      filter(
        item=>permissions.includes(item.child_resource.toUpperCase()) && 
        activeDashboards(item).length > 0
      )
      :[]);
  }                  
  useEffect(()=>{
    console.log('clinicDashboards',clinicDashboards,getMenuIndex());
    if(isDashboardPage){
      const mi = getMenuIndex();
      !expanded.includes(mi) && setExpanded([mi]);
    }
  },[clinicDashboards])
  const getMenuIndex = () => {
    const indexBasedOnPath = userDashborads().findIndex((e) => !!e.dashboards.find(i => pathName.includes(`/${i.dashboard_name.toLowerCase()}`)));
    if (indexBasedOnPath > 0) {
      return indexBasedOnPath;
    }
    return 0;
  }
  //console.log('R clinicDashboards',clinicDashboards);
  const [expanded, setExpanded] = useState([0]);
  //const [collapsed,setCollapsed] = useState(false);
  //const [toggleState, setToggleState] = useState(true);
  const collapsed = !open;
  const handleChange = panel => (_event, isExpanded) => {
    const _i = expanded.indexOf(panel);
    const u = isExpanded?_i<0?[panel]:[]:_i>-1 && expanded.splice(_i,1)?[]:[];
    setExpanded([...expanded,...u]);
  };
  const hasLeftMenu = pathName.includes(GlobalRoutePath.ADT_AUTOMATION.path) || pathName.includes(GlobalRoutePath.ADT_AUTOMATION_HISTORY.path);

  var triaViewPath = matchPath({path: GlobalRoutePath.TRIAL_VIEW.path, caseSensitive: true, end: true},pathName);
  const dashboardPath = matchPath({path: GlobalRoutePath.DASHBOARD.path, caseSensitive: true, end: true},pathName);
  const hasPermission = item => !item.privilege || permissions.includes(item.privilege)
  const dashUrl = (dashName)=>GlobalRoutePath.LANDING.path+ "/" + dashName.toLowerCase()

  const renderItems = ()=>{
    if (pathName.includes(GlobalRoutePath.ADT_AUTOMATION.path) || pathName.includes(GlobalRoutePath.ADT_AUTOMATION_HISTORY.path)) {
      return (
        <ul className={classes.mainMenuesContainer}>
            <li>
              <span>ADT Automation</span>
              <ul>
                {adtAutoNavItems.map(item => {
                  return (
                    <li key={item.label}>
                      <Link className={(pathName === item.to) ? classes.activeMenu : ''} to={item.to}>{item.label}</Link>
                    </li>
                  )
                })}
              </ul>
            </li>
        </ul>
      )
      
    } else if (isDashboardPage && clinicDashboards[selectedClinicId]) {

      const dashboards = userDashborads();
        return (
          <>
            {(!!dashboards.length || !!searchText.length) && <div>
              <TextFieldWrapper 
                placeholder="Search Dashboard" 
                className="search" 
                value={searchText}
                onChange={(e)=>setSerchText(e.target.value)}
                slotProps={{
                  input: searchText.length?{
                    endAdornment: (
                      <InputAdornment position="end" onClick={()=>setSerchText('')}>
                        <ClearIcon />
                      </InputAdornment>
                    )
                  }:{}
                }}
              />
              </div>}
            <div>
              {dashboards.map((item, index) => {
                //const permissionExist = permissions["READ"].filter(val => val === item.child_resource.toUpperCase())
  
                return (
                    <Accordion
                      expanded={!!searchText.length || expanded.includes(index)}
                      onChange={handleChange(index)}
                      className={classes.expansionPanel}
                      key={index}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                        <span>{item.name}</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul className={classes.subMenuesContainer}>
                          {activeDashboards(item).map(list => <li key={list.dashboard_name}  onClick={()=>onClick(dashUrl(list.dashboard_name))}>
                                <Link className={((dashboardPath && selectedPath == dashUrl(list.dashboard_name)) || (selectedPath == GlobalRoutePath.LANDING.path && list.display_default === true)) ? classes.activeMenu : ''} to={dashUrl(list.dashboard_name)}>{list.display_name}</Link>
                              </li>)}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  )
              })}
            </div>
          </>
        )
  
    } else if (pathName.includes(GlobalRoutePath.SETTINGS.path)) {
      return (
        <ul className={classes.mainMenuesContainer}>
            {settingsNavItems.map(item => {
              return (
                <li key={item.headerTitle}>
                  <span className='heading'>{item.headerTitle}</span>
                  <ul className={classes.subMenuesContainer}>
                    {item.subHeader.map(list => (
                      hasPermission(list)?<li key={list.label} onClick={()=>onClick(list.linkTo)}>
                        <Link  className={(selectedPath === list.linkTo) || (list.activeLinks && list.activeLinks.includes(selectedPath)) || (selectedPath === GlobalRoutePath.SETTINGS.path && list.default === true) ? classes.activeMenu : ''} to={list.linkTo}><SvgIcon title={list.label} icon={list.icon} className="mr-8 link-icon"/><span className='link-text'>{list.label}</span></Link>
                      </li>:''
                    ))}
                  </ul>
                </li>
              )
            })}
          </ul>
      )
    } else if (pathName.includes(GlobalRoutePath.PATIENT360.path)) {
      const viewUrl = (view_id)=>`${GlobalRoutePath.PATIENT360.path}/${view_id}`;
      return (
        <ul className={classes.mainMenuesContainer}>
            <li onClick={()=>onClick(GlobalRoutePath.PATIENT360.path)}>
              <Link className={(selectedPath === GlobalRoutePath.PATIENT360.path || viewDetails.length === 0) ? classes.activeMenu : ''} to={GlobalRoutePath.PATIENT360.path}><i className="fa fa-notes-medical" /> Master View</Link>
            </li>
            {
              viewDetails !== undefined ?
              viewDetails.map(item => (
                  <li key={item.view_id} onClick={()=>onClick(viewUrl(item.view_id),true)}>
                    <Link className={(selectedPath === viewUrl(item.view_id)) ? classes.activeMenu : ''} to={viewUrl(item.view_id)}>{item.view_name}</Link>
                  </li>
                )) : ""
            }
          </ul>
      )
    } else if (pathName.includes(GlobalRoutePath.PATIENTTRIAL.path)) {
      const selViewId = triaViewPath?parseInt(triaViewPath.params.viewId):trialViewList.length>0?trialViewList[0].view_id:null;
      const viewUrl = (view_id)=>`${GlobalRoutePath.PATIENTTRIAL.path}/${view_id}`;
        return (
          <ul className={classes.mainMenuesContainer}>
            {
              trialViewList.length>0?trialViewList.map(item => (
                  <li key={item.view_id} onClick={()=>onClick(viewUrl(item.view_id))}>
                    <Link className={(selViewId === item.view_id || selectedPath == viewUrl(item.view_id)) ? classes.activeMenu : ''} to={viewUrl(item.view_id)}>{item.view_name}</Link>
                  </li>
                )):''
                //<li><Link className={(pathName === "/patienttrial") ? classes.activeMenu : ''} to={"/patienttrial"}><i className="fa fa-notes-medical" /> Master View</Link></li>
            }
          </ul>
        )
    }
  }

  

  return <div className={`${classes.leftMenuContainer} ${collapsed && 'folded'}`}>
            <div className='left-subCtr'>
              <SvgIcon title={collapsed?'click here to expand':'click here collapse'} className="foldableIcon" onClick={handleClick} icon={`/static/images/${collapsed?'leftnav-expand.svg':'leftnav-collapse.svg'}`}/>
              <div className={`left-content ${dynaClass}`}>
                {renderItems()}
              </div>
              
            {/* <div className={classes.helpCenterTextContainer}>
              <SupportIcon />
              <Link to={SettingsRoutes.HELP.fullPath}>Help Center</Link>
            </div> */}
            <div className={`${classes.bottomSection} ${dynaClass}`}>
              <ul className={classes.mainMenuesContainer}>
                <li>
                {settingPath?<Link to={settingPath}>
                    <SvgIcon title="Settings" icon="/static/images/settings.svg" className="mr-8 btm-icon link-icon"/><span className='link-text'>Settings</span>
                  </Link>:<div className='empty-div'>&nbsp;</div>}
                </li>
                <li>
                  <Link to={helpPath}>
                    <SvgIcon title="Help Center" icon="/static/images/help.svg" className="mr-8 btm-icon link-icon"/><span className='link-text'>Help Center</span>
                  </Link>
                </li>
                <li>
                  <a href="#" onClick={confirmLogout}>
                    <SvgIcon title="Logout" icon="/static/images/logout.png" className="mr-8 btm-icon link-icon"/><span className='link-text'>Logout</span>
                  </a>
                </li>
              </ul>
              <div className='logoContainer'>
                <Logo className="mini"/>
              </div>
            </div>
            </div>
          </div>
}

export default LeftMenu;
