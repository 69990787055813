import { loaderActions } from "../actionCreators";

const initialState = {
  app: { isLoading: false, title: "", content: "" },
  userList: {isLoading: false},
  invitationList: {isLoading: false},
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case loaderActions.SET_APP_LOADER:
      let app = action.payload;
      if(action.checkLoaderCount && state.app.count){
        app = state.app.count > 1?{...app,count:state.app.count-1}:app;
      }
      if(action.withLoaderCount){
        app = {...app,count:((state.app.count || 0) + 1)};
      }
      return { ...state, app};
    case loaderActions.SET_CLINIC_USER_LIST_LOADER:
      return { ...state, userList: action.payload };
    case loaderActions.SET_CLINIC_USER_INVITATION_LIST_LOADER:
      return { ...state, invitationList: action.payload };
    default:
      return state;
  }
};

export default loaderReducer;



 