import React, { useState,useEffect,useRef } from "react";

export const ONE_SEC = 1000;
export const ONE_MIN = 60*ONE_SEC;
export const TIME_FORMAT = {sec:'seconds',mint:'minutes'};
const paddZerro = (num)=>(num>9?'':'0')+num;
const TickTimer = ({startTime=Date.now(),endTime,interval=ONE_SEC,onEnd,format=TIME_FORMAT.sec})=>{
    const [state,setState] = useState({time:(endTime-startTime)/ONE_SEC})
    const timer = useRef();
    const updateTimer = ()=>{
        const rtime = endTime-Date.now();
        if(rtime>0){
            const nt = rtime/ONE_SEC;
            setState({time:state.time == nt?(state.time+1):nt})
        }
        else{
            onEnd(startTime)
            clearTimer();
            setState({time:0});
        }
    }
    const clearTimer = ()=>{
        timer.current && clearTimeout(timer.current);
        timer.current = null;
    }
    useEffect(()=>{
        clearTimer();
        timer.current = setInterval(updateTimer, interval)
        return ()=> clearTimer();
    },[endTime])
    useEffect(()=>{
        return()=>clearTimer()
    },[])
    const renderTime = ()=>{
        switch(format){
            case TIME_FORMAT.mint : {
                const m = parseInt(state.time/60);
                const s = parseInt(state.time % 60);
                return `${paddZerro(m)}:${paddZerro(s)}`;
            }
            default: return parseInt(state.time);
        }
    }
    
    return renderTime();
}

export default TickTimer;