import React, { Fragment,useEffect,useRef, useState } from "react";

import * as errorActions from "@redux/actionCreators/ErrorActions";
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import ToastMessage from "@common/ToastMessage";
import AlertDialog from "@common/AlertDialog";
import { Box, Icon } from "@mui/material";
import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import { WIN_SCROLL } from "@utils/Events/constants";
const MoveToTop = ()=>{
    const [show,setShow] = useState(false);
    const showRef = useRef(show)
    const handlePageScroll = ()=>{
        const canShow = document.documentElement.scrollTop > (window.innerHeight * 0.35);
        if(canShow != showRef.current){
            showRef.current = canShow;
            setShow(canShow);
        }
    }
    const scrollToTop = ()=>{
        showRef.current = false;
        setShow(false);
        document.documentElement.scrollTop = 0;
    }
    useEffect(()=>{
        const evtId = _app.events.subscribe({[WIN_SCROLL]:handlePageScroll});
        return ()=> _app.events.unsubscribe(evtId)
    },[])
    return show?<Box onClick={scrollToTop} sx={{cursor:'pointer',position:'fixed',bottom:12,right:12,color:'var(--sm_primary)'}}><Icon><SwipeUpIcon/></Icon></Box>:''
}
const LayoutWrpper =({childRoute=false,pageRoute=true,children})=>{
    const dispatch = useDispatch();
    const errorRef = useRef(null);
    const error = useSelector(state=>state.errorReducer.errorCode,shallowEqual);
    const resetError = ()=>{
        //console.log('unmount LayoutWrpper',error,errorRef.current);
        !!errorRef.current && dispatch(errorActions.resetCustomAuthenticationErrorCode());
    }
    useEffect(()=>{
        errorRef.current = error;
    },[error])
    useEffect(()=>{
        //console.log('location.pathname',location.pathname);
        resetError();
    },[location.pathname])
    useEffect(()=>{
        //console.log('init LayoutWrpper',childRoute,pageRoute);
        return ()=>{
            resetError();
        }
    },[]);
    //console.log('error----',error);
    return<Fragment>
        {children}
        {!childRoute && <><ToastMessage global show={false}/>
        <MoveToTop/>
        <AlertDialog global open={false}/></>}
    </Fragment>

}

export default LayoutWrpper;