import * as Auth from "aws-amplify/auth";
import { BroadcastChannel } from "broadcast-channel";
import { loaderActions } from "../";
import { LoaderContent } from "../../../utility/constants/LoaderContent";
import {clinicActions} from "../";
import {profilePicActions} from "./"
import {platformCoreActions} from "../"

export const IHP_CHANNEL = new BroadcastChannel('ihealth-portal');
export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_EMAIL_VERIFIED = "SET_USER_EMAIL_VERIFIED";
export const SET_APP_INITIALIZED = "SET_APP_INITIALIZED";
export const RESET_USER_ON_SIGNOUT = "RESET_USER_ON_SIGNOUT";
export const SET_JWT_EXP = "SET_JWT_EXP";
export const USER_SESSION_EXP = "USER_SESSION_EXP";
export const USER_SESSION_UPDATED = "USER_SESSION_UPDATED";
export const LOGIN_CHALLENGES = {CONFIRM_SIGN_UP:'CONFIRM_SIGN_UP',SMS_MFA:'CONFIRM_SIGN_IN_WITH_SMS_CODE',NEW_PASSWORD_REQUIRED:'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'}
export const CHANNEL_EVENTS = {
  SIGN_OUT:'sign-out',
  SIGN_IN:'sign-in',
  CLINIC_SWITCH:'clinic-switch',
  SESSION_EXPIRED:'session-expired',
  SESSION_REFRESHED:'session-refreshed'
};
export const SET_USER_ATTRIBUTES = "SET_USER_ATTRIBUTES";
export const SET_USER_FORCE_PASSWORD_CHANGE = "SET_USER_FORCE_PASSWORD_CHANGE";
export const SET_USER_CHALLENGE = "SET_USER_CHALLENGE";
export const RESET_LOGIN = "RESET_LOGIN";
export const appContext = {uid:`${Date.now()}_${Math.random()*1000}`,user:null,cid:null,refresh:false,ltat:null};
/* IHP_CHANNEL.onmessage = (message)=>{
  console.log('BroadcastChannel',appContext.uid,message);
  switch(message.event){
    case CHANNEL_EVENTS.SIGN_IN: !appContext.user && window.location.reload();break;
    case CHANNEL_EVENTS.SIGN_OUT: appContext.user && window.location.reload();break;
    //case CHANNEL_EVENTS.SESSION_EXPIRED: break;

  }
} */
const setUserLoggedIn = isLoggedin => ({ type: SET_USER_LOGGED_IN, payload: isLoggedin });
const getLastAT = ()=> localStorage.getItem('la_time') || Date.now();
const getTET = ()=> localStorage.getItem('te_time');
const setTET = (tet)=> localStorage.setItem('te_time',tet);
const updateTokenAccessTime = (reset=false)=>{
  const latime = reset?getLastAT():Date.now()
  localStorage.setItem('la_time',latime);
  return latime;
};
export const setUserEmail = email => ({ type: SET_USER_EMAIL, payload: email });

//const setUserEmailVerified = isEmailVerified => ({ type: SET_USER_EMAIL_VERIFIED, payload: isEmailVerified });

const setAppInitialized = isInitialized => ({ type: SET_APP_INITIALIZED, payload: isInitialized });

const resetUserOnSignout = (payload) => ({ type: RESET_USER_ON_SIGNOUT,payload });

const setJWTExp = exp => ({ type: SET_JWT_EXP, payload: exp });

const setForcePasswordChange = isForcePasswordChange => ({ type: SET_USER_FORCE_PASSWORD_CHANGE, payload: isForcePasswordChange });

// Function to get the User Role Associated with the Clinic Id
const getUserRole = (userDetails) => {
  let userRole = {}
  try {
    if(userDetails.tenant_role) {
      userRole = JSON.parse(userDetails.tenant_role);
    }
  } catch(error) {
    userRole = {}
  }
  return userRole;
}

const initSessionData = (userDetails)=>{
  const inactiveTimeoutInSec = parseInt(userDetails.inactive_timeout || '60000');
  return {expired:false,email: userDetails.email,lat:Date.now(),expiry:inactiveTimeoutInSec*1000};
}
export const fetchAuthenticatedUser = ({forceCheck,refreshToken}={}) => async (dispatch, getState) => {
  let sessionData = {};
  try{
    let forceRefresh = false;
    const init=!appContext.user
    const exp = getState().userReducer.jwt.exp || getTET();
    const currentEpochInUTC = getCurrentEpoch();
    forceRefresh = refreshToken || appContext.refresh || !exp || currentEpochInUTC >= Number(exp);

    const { tokens } = await Auth.fetchAuthSession({forceRefresh});
    //console.log('tokens',tokens);
    if(!tokens){
      throw {message:'user session expired or session not available'};
    }
    const userDetails = tokens.idToken.payload;
    //const currentUser = await Auth.getCurrentUser();
    sessionData = {email: userDetails.email};
    const newExp = userDetails.exp;
    resetUserContext({user:userDetails.sub,cid:userDetails['custom:defaultClinic'],ltat:Date.now()});
    const userRole = getUserRole(userDetails);
    const inactiveTimeoutInSec = parseInt(userDetails.inactive_timeout || '60000');
    const lastATime = getLastAT();
    const lastATimediff = (Date.now() - lastATime)/1000;
    const expired = lastATimediff>=inactiveTimeoutInSec;
    const sessionWasAE = forceCheck && expired
    if(!sessionWasAE && init){
      dispatch({type:USER_SESSION_UPDATED,payload:initSessionData(userDetails)});
    }
    else{ 
      if(expired){
        //console.log('inactiveTimeoutInSec',inactiveTimeoutInSec,lastATimediff,getLastAT());
        IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SESSION_EXPIRED,uid:appContext.uid,data:{sessionData}});
        //throw new Error(CHANNEL_EVENTS.SESSION_EXPIRED,'session Expired');
        expirySession(dispatch,sessionWasAE);
      }
      else{
        IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SESSION_REFRESHED,uid:appContext.uid,data:{lat:Date.now()}});
        dispatch(refreshSession());
      }
    }
    if(newExp!=exp){
      setTET(newExp);
      dispatch(setJWTExp(newExp));//todo remove later
    }
    updateTokenAccessTime();
    return {
      email: userDetails.email,
      isEmailVerified: userDetails.email_verified,
      firstName: userDetails.given_name,
      lastName: userDetails.family_name,
      phoneNumber: userDetails.phone_number,
      address: userDetails.address?.formatted,
      token: tokens.idToken.toString(),
      defaultClinic:userDetails['custom:defaultClinic']||null,
      isFirstLogin: userDetails['custom:is_first_time_login']?userDetails['custom:is_first_time_login']:"1",
      userRole,
    };
  }
  catch(error){
    console.log('token error',error);
    //assume that refresh token expired
    if(appContext.user){
      IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SESSION_EXPIRED,uid:appContext.uid,data:{sessionData}});
      expirySession(dispatch);
    }
    throw {response:{error,status:401}};
  }

};

export const renewToken = async dispatch => {
  try{
    await dispatch(fetchAuthenticatedUser());
  }catch(error){
  }
}

export const initializeApplication = async dispatch => {
  try {

    // Check for the User Preference for RememberMe Selection
    if(localStorage.rememberMe === 'false') {
      dispatch(setAppInitialized(true));
      await onSignout(dispatch);
      return;
    }

    const userAttributes = await dispatch(fetchAuthenticatedUser({forceCheck:true}));
    dispatch(setUserAttributes({...userAttributes, isLoggedIn:true}));
    dispatch(setAppInitialized(true));
    userAttributes.defaultClinic && console.log('userAttributes.defaultClinic ',userAttributes.defaultClinic);
    userAttributes.defaultClinic && dispatch(clinicActions.updateUserSelectedClinicId(userAttributes.defaultClinic));
    dispatch(profilePicActions.fetchUserPermissions());
    dispatch(clinicActions.fetchUserClinics(userAttributes.defaultClinic));
    dispatch(profilePicActions.fetchProfilePic());
    //dispatch(platformCoreActions.fetchAnnouncements());

  } catch (error) {
    dispatch(setAppInitialized(true));
    updateTokenAccessTime(true);
  }
};

const loginSucess = loginResponse => async dispatch => {
  const defaultClinic = loginResponse['custom:defaultClinic']||null;
  setTET(loginResponse.exp);
  resetUserContext({user:loginResponse.sub,cid:defaultClinic,ltat:Date.now()});
  const userRole = getUserRole(loginResponse);
  const userAttributes = {
    isLoggedIn: true,
    challenge:null,
    jwt:{exp:loginResponse.exp},
    email: loginResponse.email,
    isEmailVerified: loginResponse.email_verified,
    firstName: loginResponse.given_name,
    lastName: loginResponse.family_name,
    phoneNumber: loginResponse.phone_number,
    address: loginResponse.address?.formatted,
    defaultClinic:defaultClinic,
    isFirstLogin: loginResponse['custom:is_first_time_login']?loginResponse['custom:is_first_time_login']:"1",
    userRole,
    sessionData:initSessionData(loginResponse)
  }
  await dispatch(setUserAttributes(userAttributes));
  await dispatch(profilePicActions.fetchUserPermissions());
  defaultClinic && (await dispatch(clinicActions.updateUserSelectedClinicId(defaultClinic)))
  dispatch(clinicActions.fetchUserClinics(defaultClinic));
  dispatch(profilePicActions.fetchProfilePic());
  //dispatch(platformCoreActions.fetchAnnouncements());
  dispatch(loaderActions.stopAppLoader());
};
export const updateUserSelectedClinicId = (clinicId)=>async dispatch =>{
  await dispatch(clinicActions.updateUserSelectedClinicId(clinicId));
}
export const handleUserNotConfirmed = email => async dispatch => {
  await Auth.resendSignUpCode({username:email});
  return await dispatch(resetUserOnSignout({email,userNotConfirmed:true}));
};

const handleForceChangePassword = (email, forcePasswordChangeFlag, loggedInFlag) => async dispatch => {
  return await Promise.all([dispatch(setForcePasswordChange(forcePasswordChangeFlag)), dispatch(setUserLoggedIn(loggedInFlag)), dispatch(setUserEmail(email))]);
};

const handleChallenge = (email, challenge,loggedInFlag) => async dispatch => {
  return await Promise.all([dispatch({ type: SET_USER_CHALLENGE, payload: challenge }), dispatch(setUserEmail(email))]);
};

export const resetUserContext = ({user=null,cid=null,ltat=null,refresh=false}={},reset=true)=>{
  const dv = reset?{}:{...appContext};
  appContext.user = user || dv.user || null;
  appContext.cid = cid || dv.cid || null;
  appContext.refresh = refresh;
  appContext.ltat = ltat || dv.ltat || null;
  window._app.u.luid = `${appContext.user}${appContext.cid}`;
}

export const login = (username, password) => async dispatch => {
  try {
    dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));
    dispatch(resetUserOnSignout({}));
    resetUserContext();//make sure user context reseted
    const {isSignedIn,nextStep} = await Auth.signIn({ username, password });
    updateTokenAccessTime();
    const challenges = Object.keys(LOGIN_CHALLENGES).reduce((pv,v)=>{pv[LOGIN_CHALLENGES[v]]=v;return pv;},{});
    // Check for the Change Password Challenge
    if(nextStep.signInStep && challenges[nextStep.signInStep]) {
      if(nextStep.signInStep === LOGIN_CHALLENGES.NEW_PASSWORD_REQUIRED) {
        dispatch(loaderActions.stopAppLoader());
        return await dispatch(handleForceChangePassword(email, true, true));
      }
      else if(nextStep.signInStep === LOGIN_CHALLENGES.CONFIRM_SIGN_UP){
        dispatch(loaderActions.stopAppLoader());
        return await dispatch(handleUserNotConfirmed(username));
      }
      else{
        dispatch(loaderActions.stopAppLoader());
        return await dispatch(handleChallenge(username, {[nextStep.signInStep]:challenges[nextStep.signInStep]}, true));
      }
    }
    const { tokens } = await Auth.fetchAuthSession();
    const loginResponse = tokens?.idToken?.payload;
    IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SIGN_IN,uid:appContext.uid});
    await dispatch(loginSucess(tokens.idToken.payload));
    return loginResponse;
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
};

export const resetLogin = () => async dispatch =>{
  dispatch({type:RESET_LOGIN});
}

export const verifyOtp = (user,otp) => async dispatch => {
  try {

    dispatch(loaderActions.startAppLoader(LoaderContent.LOGIN));
    //const user = await Auth.currentUserPoolUser();
    updateTokenAccessTime();
    // Check for the Change Password Challenge
    if(otp && user){
        await Auth.confirmSignIn({challengeResponse:otp});
        const { tokens } = await Auth.fetchAuthSession();
        IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SIGN_IN,uid:appContext.uid});
        await dispatch(loginSucess(tokens.idToken.payload));
        return tokens.idToken.payload;
    }
    return user;
  } catch (error) {
    dispatch(loaderActions.stopAppLoader());
    throw error;
  }
};

export const signout = async dispatch => {
  dispatch(loaderActions.startAppLoader(LoaderContent.SIGN_OUT));
  await onSignout(dispatch);
  dispatch(loaderActions.stopAppLoader());
};

export const clearPrvSession = async dispatch => {
  dispatch(resetUserOnSignout({}));
};

export const expirySession = async (dispatch,inForce=false) =>{
  dispatch({type:USER_SESSION_UPDATED,payload:{expired:true,inForce}});
}
export const refreshSession =(lat)=> async dispatch =>{
  dispatch({type:USER_SESSION_UPDATED,payload:{lat:lat||Date.now()}});
}
export const logout = (sessionData={}) => async dispatch => {
  dispatch(loaderActions.startAppLoader(LoaderContent.SIGN_OUT));
  await onSignout(dispatch,sessionData);
  dispatch(loaderActions.stopAppLoader());
};

const onSignout = async (dispatch,sessionData={manual:true})=>{
  updateTokenAccessTime(true);
  resetUserContext();
  await Auth.signOut();
  await dispatch(resetUserOnSignout({sessionData}));
  dispatch(clinicActions.resetUserClinics());
  IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.SIGN_OUT,uid:appContext.uid});
  resetUserContext();
  setTET(0);
}

// ********************************
// New functions are added
// ********************************

const getCurrentAuthenticatedUser = () => async (dispatch, getState) => {
  let bypassCache = false;

  const { exp } = getState().userReducer.jwt;
  const currentEpochInUTC = getCurrentUTCEpoch();
  const forceRefresh = !exp || currentEpochInUTC >= Number(exp);

  const { tokens } = await Auth.fetchAuthSession({ forceRefresh});
  const newExp = tokens.idToken.payload.exp;
  dispatch(setJWTExp(newExp));
  return tokens.idToken.payload;
};

export const setUserAttributes = (userAttributes) => dispatch => {
  dispatch({ type: SET_USER_ATTRIBUTES,
    payload:  userAttributes
  });
}

export const updateUserAttributes = (given_name, family_name, phone_number, address) => async dispatch => {

  dispatch(loaderActions.startAppLoader(LoaderContent.UPDATE_PROFILE));
  //const user = await Auth.currentAuthenticatedUser({ bypassCache });
  //const user = await dispatch(getCurrentAuthenticatedUser());

   await Auth.updateUserAttributes({userAttributes:{given_name, family_name, phone_number, address}} )
  .then(_res => {
    dispatch(setUserAttributes({
      firstName:  given_name,
      lastName: family_name,
      phoneNumber: phone_number,
      address,
    }))
    dispatch(loaderActions.stopAppLoader())
  })
  .catch(err => {
    dispatch(loaderActions.stopAppLoader())
    throw err;
  })

}

export const updateFirstTimeUserAttribute = (isFirstLogin) => async dispatch => {
  //const user = await dispatch(getCurrentAuthenticatedUser());
   await Auth.updateUserAttributes({userAttributes:{"custom:is_first_time_login": isFirstLogin}} )
  .then(_res => {
    dispatch(setUserAttributes({
      isFirstLogin,
    }))
  })
  .catch(err => {
    throw err;
  })
}

export const updateUserCustomAttributes = (attributes) => async dispatch => {
  const userAttributes = Object.keys(attributes).reduce((fa,k)=>{
    fa[`custom:${k}`] = attributes[k];
    return fa;
  },{});
  await Auth.updateUserAttributes({userAttributes} )
  .then(_res => {
    dispatch(setUserAttributes(attributes));
    Auth.fetchAuthSession({forceRefresh:true});
  })
  .catch(err => {
    throw err;
  })
}

export const forgotPassword = (username,captchToken) => dispatch => {
  return Auth.resetPassword({username})//,{captchToken}
  .then(() => {
    dispatch(setUserEmail(username));
  })
  .catch(err => {
    throw err;
  })
}

export const forgotPasswordSubmit = (username, confirmationCode, newPassword,captchToken) => dispatch => {
  dispatch(loaderActions.startAppLoader({title:'Setting  a new Password',content:'Please wait while setting the new password...'}));
  return Auth.confirmResetPassword({username, confirmationCode, newPassword})//,{captchToken}
  .then(() => {
    dispatch(setUserEmail(username));
  })
  .catch(err => {
    throw err;
  }).finally(()=>{
    dispatch(loaderActions.stopAppLoader());
  })

};

export const changePasswordSubmit = (username, oldPassword, newPassword) => async dispatch => {

  try {

    dispatch(loaderActions.startAppLoader(LoaderContent.CHANGE_PASSWORD));

    const user = await Auth.updatePassword({oldPassword,newPassword});

    // Check for the Change Password Challenge
    /* if(user.challengeName) {
      if(user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const requiredAttributes = {}
        await Auth.confirmSignIn({challengeResponse: newPassword});
      }
    } else {
      await Auth.updatePassword({oldPassword,newPassword});
    } */

    dispatch(loaderActions.stopAppLoader())
    return await dispatch(handleForceChangePassword(username, false, true));

  } catch(error) {
    dispatch(loaderActions.stopAppLoader())
    throw error;
  }

};

// *****************************************
// Generic Function - TODO - Move it to Utility Functions later
// *****************************************

const getCurrentUTCEpoch = () => {
  var currentDate = new Date();
  var currentUTCDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), currentDate.getUTCHours(), currentDate.getUTCMinutes(), currentDate.getUTCSeconds());
  var currentUTCEpoch = Math.floor(currentUTCDate.getTime() / 1000);
  return currentUTCEpoch;
};

const getCurrentEpoch = () => {
  var currentUTCEpoch = Math.floor(Date.now() / 1000);
  return currentUTCEpoch;
};
