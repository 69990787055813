import React, { useEffect,useState,forwardRef,useImperativeHandle } from "react";
import Confirm from "../Confirm";

import {SHOW_GLOBAL_ALERT,HIDE_GLOBAL_ALERT} from "@utils/Events/constants";
const AlertDialog = ({
    global=false,
    open=false,
    title,
    content,
    cancelText=null,
    onCancelClick=null,
    okText='Ok',
    onOkClick,
    className='',
    maxWidth="xs",
    maxHeight=600,
    zIndex=1301
})=>{
    const dValue = { title, content, cancelText, handleCancel: onCancelClick, confirmText: okText, handleConfirm: onOkClick,className,maxWidth,maxHeight,zIndex };
    const [state, setState] = useState({ open});
    const updateState = (v)=>setState((pv)=>({...pv,...v}));
    const closeAlert = ()=>updateState({open:false});
    const handleCancel = ()=>{
        const cb = state.onCancelClick || onCancelClick;
        cb && cb();
        closeAlert();
    }
    const handleOk = (e)=>{
        const cb = state.onOkClick || onOkClick;
        cb && cb(e);
        closeAlert();
    }
    const showAlert = (opts={})=>{
        updateState({...opts, confirmText: (opts.okText || okText),open:true});
    }
    useEffect(()=>{
        open != state.open && updateState({open});
    },[open])
    useEffect(()=>{
        const subKey = global?_app.events.subscribe({[SHOW_GLOBAL_ALERT]:showAlert,[HIDE_GLOBAL_ALERT]:closeAlert}):null;
        return()=>{
            subKey && _app.events.unsubscribe(subKey);
        }
    },[])
    //console.log('----state---',state);
    return <Confirm {...dValue} {...state} handleCancel={handleCancel} handleConfirm={handleOk}/>
}

export default AlertDialog;