import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  loaderModal:{
    '& .MuiBackdrop-root':{
      backgroundColor:'rgb(0 0 0 / 75%)'
    }
  },
  card: {
    maxWidth: 345,
    margin: "200px auto 0",
    textAlign: "center",
    '& .header':{
      background:'#4248BD',
      padding:0
    },
    "& h2": {
      padding: "6px 0",
      color: 'white',
      textTransform:'uppercase',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 0.32,
      lineHeight: "24px",
    },
    "& p": {
      paddingTop: 5,
      color: theme.palette.text.mediumShadeGray,
      fontSize: 14,
      letterSpacing: 0.25,
      lineHeight: "20px",
    },
    '&.global-loader':{
      '& .spinner':{
        padding:24
      },
      '& img':{
        maxWidth:200,
        objectFit:'scale-down'
      },
      '& .content':{
        display:'none'
      }
    },
    "& .actions":{
      display:'flex',
      justifyContent:'center',
      margin: 16
    }
  },
  circularProgressContainer: {
    paddingTop: 20,
    textAlign: "center",
    "& div": { color: theme.palette.text.primary },
    "& img":{
      maxWidth:340
    }
  },
  circularProgress: { display: "inline-block" },
}));
