const appBaseUrl = `${location.protocol}//${location.host}`;
const baseApiUrl = ()=> process.env.REACT_APP_BASE_API_URL || appBaseUrl;
const serverUrl = process.env.REACT_APP_API_SERVER_URL;
const fullUrl = (url)=> url?.startsWith('http')?url:[...(baseApiUrl().startsWith('/')?appBaseUrl:'').replace(':/',':&slash;').split('/'),...baseApiUrl().split('/'),...(url||'').split('/')].filter(c=>!!c).join('/').replace(':&slash;',':/');
export const getNonProxyUrl = (url)=>!url.startsWith(serverUrl) && url.includes(baseApiUrl())?[...serverUrl.replace(':/',':&slash;').split('/'),...(url.substring(url.indexOf(baseApiUrl())+baseApiUrl().length)).split('/')].filter(c=>!!c).join('/').replace(':&slash;',':/'):url;
export const APIEndpoints = {
  NHCAPI: {
    name: "PlatformCore",
    endpoint: fullUrl(process.env.REACT_APP_NHC_CORE_API_ENDPOINT),
  },
  NHCSERVICEAPI: {
    name: "PlatformServices",
    endpoint: fullUrl(process.env.REACT_APP_NHC_SERVICES_API_ENDPOINT),
  },
  NHCCOMMONSERVICEAPI: {
    name: "CommonServices",
    endpoint: fullUrl(process.env.REACT_APP_NHC_COMMON_API_ENDPOINT),
  },
  NHCCLINICAPI: {
    name: "ClinicServices",
    endpoint: fullUrl(process.env.REACT_APP_NHC_CLINIC_API_ENDPOINT),
  },
  NHCUSERAPI: {
    name: "UserServices",
    endpoint: fullUrl(process.env.REACT_APP_NHC_USER_API_ENDPOINT),
  },
  NHCCLINICCOREAPI: {
    name: "ClinicCoreServices",
    endpoint: fullUrl(process.env.REACT_APP_NHC_CLINIC_CORE_API_ENDPOINT),
  },
  NHCUSERACCESSAPI: {
    name: "UserAccessRoles",
    endpoint: fullUrl(process.env.REACT_APP_NHC_USER_ACCESS_API_ENDPOINT),
  },
  NHCNLPAPI: {
    name: "NLPServices",
    endpoint: fullUrl(process.env.REACT_APP_NHC_NLP_DEV_ENDPOINT)
  },
  SMART_SCHEDULER_API:{
    name: "SmartScheduler",
    endpoint: fullUrl(process.env.REACT_APP_SMART_SCHEDULER_API_ENDPOINT)
  },
  AI_SERVICES:{
    name: "Ai Services",
    endpoint: fullUrl(process.env.REACT_APP_AI_SERVICES_API_ENDPOINT)
  },
  CO_PILOT:{
    name: "Co Pilot",
    endpoint: fullUrl(process.env.REACT_APP_CO_PILOT_API_ENDPOINT)
  },
  ATLAS: {
    name: "Atlas",
    endpoint: fullUrl(process.env.REACT_APP_ATLAS_API_ENDPOINT)
  }
};

export const APIPaths = {
  SIGNUP: "/signup",
  GET_BOT_LIST: "/bot",
  GET_USER_PROFILE: "/profile",
  LEAD_GENERATION: "/lead",
  PLATFORM_SERVICES: "/services",
  PLATFORM_SERVICES_SUBSCRIBED: "/subscription",
  ADD_NEW_PLATFORM_SERVICE: "/add-subscription",
  UPDATE_PLATFORM_SERVICE_DETAILS: (id) => `/subscription/${id}`,
  CONTACT_US: "/contact_us",
  ADT_PATIENT: (followUpDays) => `/patient/followup?day=${followUpDays}`,
  ADT_PATIENT_FOLLOWUP: (ADTId) => `/followup?adt_id=${ADTId}`,
  ADT_ADD_PATIENT_FOLLOWUP: "/followup",
  ADT_FILES_PROCESSED: "/patient/import/status?status=COMPLETED",
  ADT_FILE_UPLOAD: "/upload?type=ADT_BOT_FILE",
  USER_CLINICS: "/clinics",
  DASHBOARD_URL: (child_resource, dashboardName) => `/clinic/dashboard?child_resource=${child_resource}&name=${dashboardName}`,
  IMPORT_FILE_URL:"/clinic/data/upload/url",
  EFAX_CINFIG:"/configurations/page/LR/EFAX_LISTING",
  EFAX_STATUS_COUNT:"/efax/listing/count",
  EFAX_LIST:"/efax/listing",
  EFAX_LOV_VALUES:"/efax/field/values",
  EFAX_REFRESH_LOV_VALUES:"/efax/field/values/refresh",
  EFAX_DETAILS:(faxId)=>`/efax/${faxId}`,
  EFAX_STATUS:(faxId)=>`/efax/${faxId}/status`,
  IMPORT_HISTORY_LIST:"/clinic/data/upload",
  DASHBOARD_LIST: "/clinic/dashboards",
  USER_PROFILE_PIC: "/user/profile_picture",
  USER_DETAILS: "/user/details",
  PAYERS_DETAILS: "/payer",
  PAYER_MEASURE_DETAILS: "/payer/measure",
  MEASURE_DETAILS: (payerId) => `/payer/${payerId}/measure`,
  UPDATE_MEASURES_DETAILS: (payerId) => `/payer/${payerId}/measure`,
  SUPPORT_CATEGORY: "/category",
  SUPPORT_REQUEST_LIST: "/support-request",
  SEARCH_REQUEST: "/search",
  ANNOUNCEMENT: "/announcement",
  CLINIC_LOCATION: "/location",
  CLINIC_LOCATION_UPDATE: (locationId) => `/location/${locationId}`,
  USER_INVITATION_DETAILS: "/invitation",
  USER_INVITATION_UPDATE: (invitationId) =>  `/invitation/${invitationId}`,
  USER_INVITATION_RESEND: (invitationId) =>  `/invitation/${invitationId}/invite`,
  USER_METRICS: "/metrics",
  USER_LIST: "/users",
  USER_EDIT: (email) => `/users/${email}`,
  CLINIC_LOGO_UPDATE: "/clinic/logo",
  METRICS: "/patient/metrics",
  BASIC_PATIENT_DETAILS: "/patient",
  PATIENT_HISTORY_DETAILS: "/patient/history",
  PATIENT_TREATMENT_DETAILS: "/patient/treatment",
  PATIENT_OTHER_DETAILS: (patient_account_number) =>  `/patient/${patient_account_number}/details`,
  PATIENT_FILTER_VALUES: "/patient/filters",
  PATIENT_FILTER_META_DATA: "/patient/query_metadata",
  PATIENT_FILTER_LOOK_UP_VALUES: "/patient/lookup_data",
  PATIENT_TRIAL_REPORT: "/patient/search-patient-data",
  PATIENT_TRIAL_REPORT_EXPORT:"/patient/export",
  SMART_SCHEDULER_METADATA:"/metadata/configs",
  SMART_SCHEDULER_SETTINGS_METADATA:"/metadata/settings",
  SMART_SCHEDULER_PATIENT_PREF:"/patient/appointments/preferences",
  SMART_SCHEDULER_UPDATE_PATIENT_PREF:"/patient/appointments/preferences/persist",
  SMART_SCHEDULER_ATTENDANCE_REPORT:"/appointments/providers/attendance",
  SMART_SCHEDULER_PATIENT_APPOINTMENTS:"/patient/appointments",
  SMART_SCHEDULER_PATIENT_APPOINTMENT_CALENDAR:"/patient/calendar/appointments",
  SMART_SCHEDULER_OPT_PATIENT_APPOINTMENTS:"/schedule-smart-move",
  SMART_SCHEDULER_PATIENT_MOVE_LIST:"/appointment/moves",
  SMART_SCHEDULER_UPDATE_APPOINTMENT_STATUS:"/change-appt-status",
  SMART_SCHEDULER_UPDATE_SETTINGS:"/metadata/update-settings",
  SMART_SCHEDULER_PROVIDERS:"/patient/appointments/providers",
  SMART_SCHEDULER_APPOINTMENT_NOTE: (appointment_id) => `/patient/appointments/notes/${appointment_id}`,
  SMART_SCHEDULER_MOVE_APPOINTMENT:"/move-appointment",
  SMART_SCHEDULER_APPOINTMENT_PROVIDER: (appointment_id) => `/patient/appointments/provider/${appointment_id}`,
  SMART_SCHEDULER_REVERT_APPOINTMENT:"/revert-appointment",
  SMART_SCHEDULER_APPOINTMENT_SUMMARY:"/appointment/moves_count_by_type",
  SMART_SCHEDULER_PATIENT_COMPLIANCE:"/compliance-metrics",
  SMART_SCHEDULER_PATIENT_AUTHORIZATION:"/patient/appointments/checkin/validate",
  VIEW_DETAILS: "/view",
  UPDATE_VIEW_DETAILS: (id) =>`/view/update/${id}`,
  DELETE_VIEW: (id) => `/view/${id}`,
  EXPORT_VIEW: "/patient/download",
  ACCESS_ROLE: "/roles/custom",
  ACCESS_ROLE_DETAILED: "/roles/custom/detailed",
  ACCESS_ROLE_OPERATION: (id) => `/roles/custom/${id}`,
  CLINIC_BASED_PERMISSIONS: "/permissions",
  AUDIO_TO_TEXT: "/text",
  AUDIO_TO_DATA: "/data/audio",
  TEXT_TO_DATA: "/data/text",
  FEEDBACK: "/feedback",
  BOT_CREATION: "/create",
  BOT_CONFIGURATION: "/configuration"
};
// TODO: To be deleted, kept only of ref during code changes
//DASHBOARD_URL: (clinicId, dashboardName) => `/clinic/${clinicId}/dashboard?name=${dashboardName}`,
