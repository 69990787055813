import { GlobalRoutePath } from "@router/constants";
export const navbarItems = [
  {
    label: "Dashboards",
    type: "link",
    openInNewTab: false,
    activeLinks: [GlobalRoutePath.LANDING.path],
    to: GlobalRoutePath.LANDING.path,
    privilege: GlobalRoutePath.LANDING.privilege,
    //icon: "/static/images/dashboard.svg"
  },
  {
    label: "Manage Practices",
    type: "select",
    activeLinks: [GlobalRoutePath.PRACTICES.path, GlobalRoutePath.JOB_SCHEDULER_EXECUTED.path, GlobalRoutePath.JOB_DASHBOARD.path],
    privilege: GlobalRoutePath.PRACTICES.privilege,
    services: [
      {
        label: "Jobs Dashboard",
        privilege: GlobalRoutePath.JOB_DASHBOARD.privilege,
        to: GlobalRoutePath.JOB_DASHBOARD.path,
      },
      {
        label: "Setup/ Configuration",
        privilege: GlobalRoutePath.PRACTICES.privilege,
        to: GlobalRoutePath.PRACTICES.path,
      },
      {
        label: "Jobs Tracking",
        privilege: GlobalRoutePath.JOB_SCHEDULER_EXECUTED.privilege,
        to: GlobalRoutePath.JOB_SCHEDULER_EXECUTED.path,
      }
    ]
  },
  {
    label: "Patient 360",
    type: "link",
    privilege: GlobalRoutePath.PATIENT360.privilege,
    openInNewTab: false,
    activeLinks: [GlobalRoutePath.PATIENT360.path],
    to: GlobalRoutePath.PATIENT360.path,
    //icon: "/static/images/patient-360.svg",
    isHidden: ({ clinicId, clinicDashboards, permissions }) =>
      !permissions.includes(GlobalRoutePath.PATIENT360.privilege)
      /*  ||
      (clinicDashboards.init &&
        !!clinicDashboards[clinicId] &&
        (!clinicDashboards[clinicId].category ||
          !clinicDashboards[clinicId].category.length)), */
  },
  {
    label: "Patient Trial",
    type: "link",
    privilege: GlobalRoutePath.PATIENTTRIAL.privilege,
    openInNewTab: false,
    activeLinks: [GlobalRoutePath.PATIENTTRIAL.path],
    to: GlobalRoutePath.PATIENTTRIAL.path
  },
  {
    label: "Smart Scheduler",
    type: "link",
    privilege: GlobalRoutePath.SMART_SCHEDULER.privilege,
    openInNewTab: false,
    activeLinks: [GlobalRoutePath.SMART_SCHEDULER.path],
    to: GlobalRoutePath.SMART_SCHEDULER.path,
    //icon: "/static/images/smart-scheduler.svg",
  },
  {
    label: "eFax",
    type: "link",
    privilege: GlobalRoutePath.EFAX.privilege,
    openInNewTab: false,
    activeLinks: [GlobalRoutePath.EFAX.path],
    to: GlobalRoutePath.EFAX.path,
    //icon: "/static/images/smart-scheduler.svg",
  },
  {
    label: "AI Services",
    id: "AI_SERVICES",
    privilege: GlobalRoutePath.AI_SERVICES.privilege,
    type: "select",
    activeLinks: [GlobalRoutePath.AI_SERVICES.path],
    //icon: "/static/images/ai-services.svg",
    services: [
      {
        label: "Speech To Text",
        privilege: "TOOLS_VADAAMI",
        to: "/ai-services/speech-to-text",
      },
      {
        label: "Copilot",
        privilege: "TOOLS_COPILOT",
        to: "/ai-services/copilot",
      },
      //{ label: "eFax", privilege: "TOOLS_EFAX", to: "/ai-services/e-fax" },
    ],
  },
  {
    label: "Patient Forms",
    type: "link",
    privilege: GlobalRoutePath.SMART_FORMS.privilege,
    openInNewTab: false,
    activeLinks: [GlobalRoutePath.SMART_FORMS.path],
    to: GlobalRoutePath.SMART_FORMS.path,
    //icon: "/static/images/smart-forms.svg",
  },
  /* {
    label: "Settings",
    id: "SETTINGS",
    type: "select",
    activeLinks: ["/settings"],
    icon: "/static/images/settings.svg",
    services: [
      {
        label: "Service Subscription",
        privilege: "SERVICE SUBSCRIPTION",
				icon:"/static/images/subscription.svg",
        to: "/settings",
      },
      {
        label: "Payers And Measures",
        privilege: "PAYER MEASURE",
				icon:"/static/images/clinic.svg",
        to: "/settings/payersandmeasures",
      },
      {
        label: "Clinic Management",
        privilege: "CLINIC MANAGEMENT",
				icon:"/static/images/clinic.svg",
        to: "/settings/clinicmanagement",
      },
      {
        label: "Customize App",
        privilege: "APP CUSTOMIZATION",
				icon:"/static/images/customize.svg",
        to: "/settings/customizeapp",
      },
      {
        label: "User Management",
        privilege: "USER MANAGEMENT",
				icon:"/static/images/users.svg",
        to: "/settings/usermanagement",
      },
      {
        label: "User Role Management",
        privilege: "ROLE MANAGEMENT",
				icon:"/static/images/user-roles.svg",
        to: "/settings/userrolemanagement",
      },
      { label: "Data Import", 
        privilege: "DATA", 
        to: "/settings/dataimport" ,
				icon:"/static/images/data-import.svg",
      },
      {
        label: "Scheduler",
        privilege: "SMART_SCHEDULER_ADMIN",
				icon:"/static/images/smart-scheduler.svg",
        to: "/settings/smartscheduler",
      },
      { label: "Help Center", to: "/settings/helpcenter",icon:"/static/images/help.svg" },
    ],
  }, */
];
