export const SET_APP_LOADER = "SET_APP_LOADER";

export const SET_CLINIC_USER_LIST_LOADER = "SET_CLINIC_USER_LIST_LOADER";
export const SET_CLINIC_USER_INVITATION_LIST_LOADER = "SET_CLINIC_USER_INVITATION_LIST_LOADER";

export const startAppLoader = loaderContent => ({
  type: SET_APP_LOADER,
  withLoaderCount:!!loaderContent.withLoaderCount,
  payload: { isLoading: true, title: loaderContent.title, content: loaderContent.content,loader: loaderContent.loader},
});

export const stopAppLoader = ({checkLoaderCount}={}) => ({
  type: SET_APP_LOADER,
  checkLoaderCount,
  payload: { isLoading: false, title: "", content: "" },
});

export const startInlineLoader = (inlineLoaderType) => ({
  type: inlineLoaderType,
  payload: { isLoading: true },
});

export const stopInlineLoader = (inlineLoaderType) => ({
  type: inlineLoaderType,
  payload: { isLoading: false },
});