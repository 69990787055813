import React, {useState,useEffect}  from "react";
import { useDispatch,useSelector,shallowEqual  } from "react-redux";
import AppHeader from "../Header/AppHeader";
import LeftMenu from '../LeftMenu'
import {useStyles} from "./styles";
import {PermissionContext} from "../PermissionContext";
import Announcement from "../../components/Header/Announcement";

import Grid from '@mui/material/Grid2';
const stateSelector = state => ({
  selectedClinicId: state.clinicReducer.selectedClinicId,
  userRole: state.userReducer.userRole,
  userPermissionsBasedOnClinic: state.userReducer.userPermissionsBasedOnClinic,
});

const LeftMenuAndFooter = (props) => {
  const { selectedClinicId, userRole, userPermissionsBasedOnClinic } = useSelector(state => stateSelector(state),shallowEqual);
  var permissions,privileges = [],checkPermission = false;
  if (userRole[selectedClinicId] !== undefined && userPermissionsBasedOnClinic[selectedClinicId] !== undefined) {
    checkPermission = true;
    userRole[selectedClinicId].forEach(role => {
      permissions = userPermissionsBasedOnClinic[selectedClinicId][role] || {};
      privileges = [...privileges,...(permissions["READ"] || []),...(permissions["WRITE"] || [])];
    });
    //console.log('privileges',privileges);
  }
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(true);
  const [refresh,setRefresh] = useState(true);

  const handleIconClick = () => {
    setIsOpen(!isOpen);
  }

  const handleClick = () => {
    setOpen(!open);
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  let wrapperClassName = `page-content-ctr ${classes.AdminWraper} ${!isOpen?classes.activeClass:''} ${props.containerClass || ''}`;
  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isLeftMenuOpen: open});
    }
    return child;
  });
  return (
    <>
    <PermissionContext.Provider value={privileges}>
      <AppHeader
        handleIconClick={handleIconClick}
        isTopMenuFullWidth={isOpen}
        checkPermission={checkPermission}
      />
      <Announcement />
      <Grid className={wrapperClassName}>
        {props.showLeftMenu && <LeftMenu
          handleClick={handleClick}
          onRefresh={onRefresh}
          open={open}
          isLeftFullwidth={!isOpen}
        />}
        <div className={`page-data ${classes.AdminUser} ${props.contentClass||''} `}>
          <div className={`${classes.dashboardsMainParent} page-data-child`}>
            {childrenWithProps}
          </div>
      </div>
    </Grid>   
    </PermissionContext.Provider>
    </>
  );
}

export default LeftMenuAndFooter
