import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { useStyles } from "./styles";

export const Loader = ({ isLoading, title, content,actions,loader,className="model-body",onClose,onClick,global }) => {
  const classes = useStyles();

  return (
    <Modal className={`${classes.loaderModal} loader-modal-root ${global?'global':'inline'}`} open={isLoading} onClose={onClose} onClick={onClick}>
      <Card className={`${classes.card} ${className} ${global && 'global-loader'}`} onClick={onClick}>
        {title && <><CardHeader className="header" title={<h2>{title}</h2>} />
        <Divider /></>}
        <div className={`${classes.circularProgressContainer} spinner `}>
          {(loader||global)?<img src={loader||'/static/images/loader.gif'}/>:<CircularProgress className={classes.circularProgress} />}
        </div>
        <CardContent className="model-content">
          <Typography variant="body2" component="p" className="content">
            {content}
          </Typography>
          {actions && <><Divider /><div className="actions"> {actions}</div></>}
        </CardContent>
      </Card>
    </Modal>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.object,PropTypes.string])
};

export default Loader;
