import React, { useState,useRef,useEffect } from "react";
import { useSelector, useDispatch,shallowEqual } from "react-redux";
import {useNavigate} from "react-router-dom";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from "@mui/material/Avatar";
import { withStyles } from "@mui/styles";

import UserProfilePopUp from "./UserProfilePopUp";
import { useStyles } from "./styles";
import {clinicActions} from "@redux/actionCreators"
import { loginActions } from "@redux/actionCreators/UserActions";
import * as loaderActions from "@redux/actionCreators/LoaderActions";
import { GlobalRoutePath } from "@router/constants";

import SvgIcon from "../../common/SvgIcon";


const LoggedInActions = ({ classes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userClinics = useSelector(state => state.clinicReducer.userClinics,shallowEqual);
  const defaultClinic = useSelector(state => state.userReducer.defaultClinic,shallowEqual);
  const selectedClinicId = useSelector(state => state.clinicReducer.selectedClinicId,shallowEqual);
  const profilePicURL = useSelector(state => state.userReducer.profilePicURL,shallowEqual);
  const clinicDashboards = useSelector(state => state.clinicReducer.clinicDashboards,shallowEqual);
  const selectedClinic = useRef(null);
  userClinics.length > 1 && !selectedClinic.current && (selectedClinic.current = userClinics.find(c=>c.clinic_id == selectedClinicId))
  useEffect(()=>{
    !defaultClinic && selectedClinicId && updateDefaultClinic(selectedClinicId); 
    selectedClinic.current && selectedClinic.current.clinic_id!=selectedClinicId && (selectedClinic.current = userClinics.find(c=>c.clinic_id == selectedClinicId));
  },[selectedClinicId])
  const [showProfilePopup, setShowProfilePopup] = useState(false);

  const handleProfileIconClick = () => {
    setShowProfilePopup(!showProfilePopup);
  };
  const updateDefaultClinic = async (clinicId)=>{
    try{
      await loginActions.resetUserContext({cid:clinicId},false);
      await dispatch(loginActions.updateUserCustomAttributes({defaultClinic:clinicId}));
    }catch(e){
      console.log('updateUserCustomAttributes failed',e);
    }
  }
  const handleClinicChange = async (newValue) => {
    const clinicId = newValue?.clinic_id;
    const {IHP_CHANNEL,CHANNEL_EVENTS,appContext} = loginActions
    if(clinicId && selectedClinic.current?.clinic_id!=clinicId){
      console.log('handleClinicChange-clinicId',newValue);
      selectedClinic.current = newValue;
      dispatch(loaderActions.startAppLoader({title: 'Loading the Clinic configurations', content:'Please wait while loading the Clinic configurations...'}));
      await updateDefaultClinic(clinicId);
      await dispatch(clinicActions.updateUserSelectedClinicId(clinicId));
      navigate(GlobalRoutePath.LANDING.path);
      setTimeout(()=>IHP_CHANNEL.postMessage({event:CHANNEL_EVENTS.CLINIC_SWITCH,uid:appContext.uid,data:{clinicId}}),1000);
    }
  }
  return (
    <div className={classes.loggedInActionsContainer}>
      <div className={classes.clinicSelectorContainer}>
      {userClinics.length > 1 && <Autocomplete
        value={selectedClinic.current}
        //isOptionEqualToValue={(item,val)=>item?.clinic_id == val}
        onChange={(event, newValue) => {
          handleClinicChange(newValue);
        }}
        id="clinic"
        renderOption={(p,option,{selected})=><div onClick={()=>handleClinicChange(option)} key={option.clinic_id} title={option.name} className={`${classes.clinicDropItem} selected-${selected}`}>{option.name}</div>}
        options={userClinics}
        getOptionLabel={(opt)=>opt.name}
        renderInput={(params) => <TextField {...params} />}
      />}
        {/*userClinics.length > 1 && <Select
          id="clinic"
          value={selectedClinicId}
          onChange={handleClinicChange}
          displayEmpty
        >
        <MenuItem className={classes.disabledMenuItem} value="" disabled>Clinic Selector</MenuItem>
          {userClinics.map((item, index) => (
            <MenuItem key={index} value={item.clinic_id}>{item.name}</MenuItem>
          ))}  
        </Select> */}  
      </div>
      <div className={classes.iconsContainer}>
        {/* <SvgIcon className={classes.NotificationsIcon} icon="/static/images/notifications.svg"/> */}
        <Avatar
              className={classes.AccountCircleIcon}
              src={profilePicURL || '/static/images/avatar-default.svg'}
              onClick={handleProfileIconClick}
            />
        {showProfilePopup && <UserProfilePopUp show={showProfilePopup} handleClose={() => setShowProfilePopup(false)} />}
      </div>
    </div>
  );
};

export default withStyles(useStyles)(LoggedInActions);
